<template>
  <ui-card
    v-loading="loading"
    :heading="`${$t('actions.create')} ${$tc('models.company')}`"
  >
    <company-form
      :company="company"
      @submit="handleSubmit"
      @cancel="handleCancel"
    ></company-form>
  </ui-card>
</template>

<script>
import UiCard from "@/components/ui/UiCard";
import CompanyForm from "../components/CompanyForm";
import { createCompany } from "../api";

export default {
  data() {
    return {
      company: {
        name: "",
        description: "",
      },
      loading: false,
    };
  },

  components: {
    UiCard,
    CompanyForm,
  },

  methods: {
    async handleSubmit() {
      this.loading = true;
      const company = await createCompany(this.company);
      this.$router.push({ name: "companies-show", params: { id: company.id } });
      this.loading = false;
    },

    handleCancel() {
      this.$router.back();
    },
  },

  mounted() {
    this.$store.commit("changeBreadcrumbs", [
      { name: "models.companies", link: "/app/companies/" },
      { name: "actions.create", link: "" },
    ]);
  },
};
</script>
